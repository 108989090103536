import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './assets/pd-mr.css';
import './assets/App.css';

import ScrollToTop from './components/ScrollToTop'

/* Landing Page */
/*
import LandingPage from './wrapper/landing-page';
import Home from './pages/landing/Home';
import Pricing from './pages/landing/Pricing';
import Features from './pages/landing/Features'
import Demo from './pages/landing/Demo'
import Blog from './pages/landing/Blog'
import Faq from './pages/landing/Faq'
import Contact from './pages/landing/Contact'
import Term from './pages/landing/Term'
import Privacy from './pages/landing/Privacy'
import Blog1 from './pages/landing/blog/the-problem-with-text-message-marketing-in-2021';
import Blog2 from './pages/landing/blog/email-vs-text-message-marketing';
import Blog3 from './pages/landing/blog/peer-to-peer-text-marketing';
*/

/*Client Pages */
import Client from './wrapper/client';
import Dashboard from './pages/client/Dashboard';
import Conversations from './pages/client/Conversations';
import ConversationsView from './pages/client/ConversationsView';
import Insight from './pages/client/Insight';
import Orders from './pages/client/Orders';
import Collaborate from './pages/client/Collaborate';
import Billing from './pages/client/Billing';
import PdfInvoice from './pages/client/PdfInvoice';
import Support from './pages/client/Support';
import Settings from './pages/client/Settings';
import Login from './pages/client/Login';
import Forgot from './pages/client/Forgot';
import Logout from './pages/client/Logout';
import Segments from './pages/client/Segments';


/* Onboarding */
import OBPlans from './pages/onboarding/Plans';
import OBLogin from './pages/onboarding/Login';
import OBForgot from './pages/onboarding/Forgot';
import OBSignup from './pages/onboarding/Signup';
import OBWelcome from './pages/onboarding/Welcome';
import OBDiscount from './pages/onboarding/Discount';
import OBDiscountPP from './pages/onboarding/Discount_pp';
import OBContactInfo from './pages/onboarding/Contactinfo';
import OBPages from './pages/onboarding/Pages';
import OBComplete from './pages/onboarding/Finalize';
import Authorize from './pages/onboarding/Authorize';
import SegmentNew from './pages/client/SegmentNew';
import SegmentsEdit from './pages/client/SegmentsEdit';

function App() {
  return (
      <>
        <BrowserRouter>
        <ScrollToTop />
          <Switch>
              {/*Landing Page */}
              {/*
              <Route exact path="/"><LandingPage><Home/></LandingPage></Route>
              <Route path="/pricing"><LandingPage><Pricing/></LandingPage></Route>
              <Route path="/features"><LandingPage><Features/></LandingPage></Route>
              <Route path="/demo"><LandingPage><Demo /></LandingPage></Route>
              <Route exact path="/blog"><LandingPage><Blog /></LandingPage></Route>
              <Route path="/blog/the-problem-with-text-message-marketing-in-2021"><LandingPage><Blog1 /></LandingPage></Route>
              <Route path="/blog/email-vs-text-message-marketing"><LandingPage><Blog2 /></LandingPage></Route>
              <Route path="/blog/peer-to-peer-text-marketing"><LandingPage><Blog3 /></LandingPage></Route>
              <Route path="/faq"><LandingPage><Faq /></LandingPage></Route>
              <Route path="/contact"><LandingPage><Contact /></LandingPage></Route>
              <Route path="/terms"><LandingPage><Term /></LandingPage></Route>
              <Route path="/privacy"><LandingPage><Privacy /></LandingPage></Route>
              */}

              {/* Client Panel */}
              <Route exact path="/"><Client><Dashboard /></Client></Route>
              <Route exact path="/dashboard"><Client><Dashboard /></Client></Route>
              <Route path="/dashboard/conversations/:page?"><Client><Conversations /></Client></Route>
              <Route path="/dashboard/conversation/view/:id"><Client><ConversationsView /></Client></Route>
              <Route path="/dashboard/insight"><Client><Insight /></Client></Route>
              <Route path="/dashboard/orders/:page?"><Client><Orders /></Client></Route>
              <Route path="/dashboard/collaborate"><Client><Collaborate /></Client></Route>
              <Route path="/dashboard/billing/:page?"><Client><Billing /></Client></Route>
              <Route path="/dashboard/download/invoice/:id"><PdfInvoice /></Route>
              {/* <Route path="/dashboard/events/:page?"><Client><Events /></Client></Route> */}
              <Route path="/dashboard/support"><Client><Support /></Client></Route>
              <Route path="/dashboard/settings"><Client><Settings /></Client></Route>
              <Route exact path="/dashboard/segments"><Client><Segments /></Client></Route>
              <Route path="/dashboard/segments/new"><Client><SegmentNew /></Client></Route>
              <Route path="/dashboard/segments/:id"><Client><SegmentsEdit /></Client></Route>
              <Route path="/login"><Login /> </Route>
              <Route path="/forgot-password"><Forgot /> </Route>
              <Route path="/dashboard/logout"><Logout /> </Route>

              {/* Onboarding */}
              <Route path="/onboarding/plans/:token"><OBPlans /> </Route>
              <Route path="/onboarding/login"><OBLogin /> </Route>
              <Route path="/onboarding/forgot"><OBForgot /> </Route>
              <Route path="/onboarding/register"><OBSignup /> </Route>
              <Route path="/onboarding/welcome"><OBWelcome /> </Route>
              <Route path="/onboarding/discount"><OBDiscount /> </Route>
              <Route path="/onboarding/post-purchase"><OBDiscountPP /> </Route>
              <Route path="/onboarding/contact-info"><OBContactInfo /> </Route>
              <Route path="/onboarding/pages"><OBPages /> </Route>
              <Route path="/onboarding/complete"><OBComplete /> </Route>
              <Route path="/authorize/:token"><Authorize /> </Route>

              {/* Admin Panel */}

          </Switch>
        </BrowserRouter>
      </>
  );
}

export default App;