import React, {Component} from "react";
import { Container, Col, Row, Card, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';

import AvgOrderValue from '../../components/charts/Avgordervalue';
import Conversations from "../../components/charts/Conversations";
import Quantityp from "../../components/charts/Quantityp";
import Recovery from "../../components/charts/Recovery";
import Today from "../../components/charts/Today";
import Week from "../../components/charts/Week";
import Month from "../../components/charts/Month";
import Year from "../../components/charts/Year";
import Custom from "../../components/charts/Custom";
import Funnel from "../../components/charts/Funnel";

import config from '../../config'
const TP_API_URL = config.TP_API_URL;
let source = axios.CancelToken.source();

class Insight extends Component{

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        start:moment().subtract(9, 'days'),
        end:moment(),
        label:moment().subtract(9, 'days').format('MMM D, YYYY') + ' - ' + moment().format('MMM D, YYYY'),
        CDtoday: {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp",
            xaxis:{},
            yaxis:{},
            yaxis2:{}
        },
        CDweek: {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp",
            xaxis:{},
            yaxis:{},
            yaxis2:{}
        },
        CDmonth: {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp",
            xaxis:{},
            yaxis:{},
            yaxis2:{}
        },
        CDyear: {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp",
            xaxis:{},
            yaxis:{},
            yaxis2:{}
        },
        CDcust: {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp",
            xaxis:{},
            yaxis:{},
            yaxis2:{}
        },
        aovot:{},
        qop:{},
        crot:{},
        rr:{},
        funnel:[
            {
                "value": 100,
                "name": "Initiated conversations",
                "fill": "#8884d8"
            },
            {
                "value": 80,
                "name": "Replied",
                "fill": "#83a6ed"
            },
            {
                "value": 50,
                "name": "Purchased",
                "fill": "#8dd1e1"
            },
        ]
    }

    handleCallback = (start, end) => {
        this.setState({ 
            start: start,
            end: end,
            label:start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY')
        });

        this.getData();
    };

    setData = (name, v1, v2, c1, c2, c3) => {
        this.setState({
            [name] : {
                v1:v1,
                v2:v2,
                c1:c1,
                c2:c2,
                c3:c3
            }
        });
    }
    

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({loading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/charts/data", {
            params:{shop_id:tp_shop, start:this.state.start.format('YYYY-MM-DD'), end:this.state.end.format('YYYY-MM-DD')},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    aovot:response.aovot,
                    CDtoday:response.sales_day,
                    CDweek:response.sales_week,
                    CDmonth:response.sales_month,
                    CDyear:response.sales_year,
                    CDcust:response.sales_custom,
                    qop:response.qop,
                    crot:response.crot,
                    rr:response.rr,
                    funnel:response.funnel
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }

    componentDidMount(){
        this.getData();
    }

    render() {
        
        return (
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <>
                <Container fluid>
                    <Row>
                        <Col lg={9} xs={3}>
                            <h3 className="page-heading sidebar-btn">
                                Insight
                            </h3>
                        </Col>
                        <Col lg={3} xs={9}>
                            <DateRangePicker
                                initialSettings={{
                                startDate: this.state.start,
                                endDate: this.state.end,
                                ranges: {
                                    'Last 7 Days': [
                                    moment().subtract(6, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'Last 10 Days': [
                                        moment().subtract(9, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                    moment().subtract(29, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                },
                                }}
                                onCallback={this.handleCallback}
                            >
                                <div
                                id="reportrange"
                                className="form-control-sm form-control mt-23"
                                style={{
                                    background: '#fff',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                    border: '1px solid #ccc',
                                    width: 'auto',
                                    display:'inline-block',
                                    float:'right'
                                }}
                                >
                                <i className="fa fa-calendar"></i>&nbsp;
                                <span>{this.state.label}</span> <i className="fa fa-caret-down"></i>
                                <input type="hidden" id="startdate" value={this.state.start.format('YYYY-MM-DD')} />
                                <input type="hidden" id="enddate" value={this.state.end.format('YYYY-MM-DD')} />
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h4 className="page-subheading">Sales Overview of recovered carts</h4>
                        </Col>
                    </Row>
                    <Tab.Container id="chart-tab" defaultActiveKey="first" >
                        <Row className="chart-tab">
                            <Col md={4} className="d-none d-md-block">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <Row >
                                                <Col md={7} xs={6}>
                                                    <p className="m-0">Selected date range</p>
                                                    <h5 className="fw-bold">${this.state.CDcust.v1} 
                                                        {(this.state.CDcust.c1>0) ?
                                                            <small className={this.state.CDcust.c2}>
                                                                {(this.state.CDcust.c3==='faArrowUp') ? 
                                                                <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                                <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                                {this.state.CDcust.c1}%
                                                            </small>
                                                            : ""
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col md={5} xs={6} className="text-right text-muted">
                                                    <p className="m-0">Previous</p>
                                                    <h5>${this.state.CDcust.v2}</h5>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <Row >
                                                <Col md={7} xs={6}>
                                                    <p className="m-0">Today</p>
                                                    <h5 className="fw-bold">${this.state.CDtoday.v1} 
                                                        {(this.state.CDtoday.c1>0) ?
                                                            <small className={this.state.CDtoday.c2}>
                                                                {(this.state.CDtoday.c3==='faArrowUp') ? 
                                                                <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                                <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                                {this.state.CDtoday.c1}%
                                                            </small>
                                                            : ""
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col md={5} xs={6} className="text-right text-muted">
                                                    <p className="m-0">Yesterday</p>
                                                    <h5>${this.state.CDtoday.v2}</h5>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <Row >
                                                <Col md={7} xs={6}>
                                                    <p className="m-0">This week</p>
                                                    <h5 className="fw-bold">${this.state.CDweek.v1} 
                                                        {(this.state.CDweek.c1>0) ?
                                                            <small className={this.state.CDweek.c2}>
                                                                {(this.state.CDweek.c3==='faArrowUp') ? 
                                                                <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                                <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                                {this.state.CDweek.c1}%
                                                            </small>
                                                            : ""
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col md={5} xs={6} className="text-right text-muted">
                                                    <p className="m-0">Last week</p>
                                                    <h5>${this.state.CDweek.v2}</h5>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">
                                            <Row >
                                                <Col md={7} xs={6}>
                                                    <p className="m-0">This month</p>
                                                    <h5 className="fw-bold">${this.state.CDmonth.v1} 
                                                        {(this.state.CDmonth.c1>0) ?
                                                            <small className={this.state.CDmonth.c2}>
                                                                {(this.state.CDmonth.c3==='faArrowUp') ? 
                                                                <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                                <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                                {this.state.CDmonth.c1}%
                                                            </small>
                                                            : ""
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col md={5} xs={6} className="text-right text-muted">
                                                    <p className="m-0">Last month</p>
                                                    <h5>${this.state.CDmonth.v2}</h5>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth">
                                            <Row >
                                                <Col md={7} xs={false}>
                                                    <p className="m-0">This year</p>
                                                    <h5 className="fw-bold">${this.state.CDyear.v1} 
                                                        {(this.state.CDyear.c1>0) ?
                                                            <small className={this.state.CDyear.c2}>
                                                                {(this.state.CDyear.c3==='faArrowUp') ? 
                                                                <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                                <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                                {this.state.CDyear.c1}%
                                                            </small>
                                                            : ""
                                                        }
                                                    </h5>
                                                </Col>
                                                <Col md={5} xs={"hidden"} className="text-right text-muted">
                                                    <p className="m-0">Last year</p>
                                                    <h5>${this.state.CDyear.v2}</h5>
                                                </Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col md={8}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0">Selected date range</p>
                                                <h3 className="fw-bold">${this.state.CDcust.v1} 
                                                    {(this.state.CDcust.c1>0) ?
                                                        <small className={this.state.CDcust.c2}>
                                                            {(this.state.CDcust.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {this.state.CDcust.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col xs={6} className="text-right text-muted">
                                                <p className="m-0">Previous</p>
                                                <h3>${this.state.CDcust.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Custom data={this.state.CDcust}></Custom>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0">Today</p>
                                                <h3 className="fw-bold">${this.state.CDtoday.v1} 
                                                    {(this.state.CDtoday.c1>0) ?
                                                        <small className={this.state.CDtoday.c2}>
                                                            {(this.state.CDtoday.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {this.state.CDtoday.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col xs={6} className="text-right text-muted">
                                                <p className="m-0">Yesterday</p>
                                                <h3>${this.state.CDtoday.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>

                                    <Today data={this.state.CDtoday}></Today>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0">This week</p>
                                                <h3 className="fw-bold">${this.state.CDweek.v1} 
                                                    {(this.state.CDweek.c1>0) ?
                                                        <small className={this.state.CDweek.c2}>
                                                            {(this.state.CDweek.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {this.state.CDweek.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col xs={6} className="text-right text-muted">
                                                <p className="m-0">Last week</p>
                                                <h3>${this.state.CDweek.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Week data={this.state.CDweek}></Week>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0">This month</p>
                                                <h3 className="fw-bold">${this.state.CDmonth.v1} 
                                                    {(this.state.CDmonth.c1>0) ?
                                                        <small className={this.state.CDmonth.c2}>
                                                            {(this.state.CDmonth.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {this.state.CDmonth.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col xs={6} className="text-right text-muted">
                                                <p className="m-0">Last month</p>
                                                <h3>${this.state.CDmonth.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Month data={this.state.CDmonth} ></Month>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col xs={6}>
                                                <p className="m-0">This year</p>
                                                <h3 className="fw-bold">${this.state.CDyear.v1} 
                                                    {(this.state.CDyear.c1>0) ?
                                                        <small className={this.state.CDyear.c2}>
                                                            {(this.state.CDyear.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {this.state.CDyear.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col xs={6} className="text-right text-muted">
                                                <p className="m-0">Last year</p>
                                                <h3>${this.state.CDyear.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Year data={this.state.CDyear} ></Year>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>

                <Container fluid className="mt-40">
                    <Col sm={12}>
                        <h4 className="page-heading">Average order value over time</h4>
                    </Col>
                    <Col sm={12}>
                        <Card body>
                            <AvgOrderValue data={this.state.aovot}></AvgOrderValue>
                        </Card>
                    </Col>
                </Container>

                <Container fluid className="mt-40">
                    <Col sm={12}>
                        <h4 className="page-heading">Conversion Funnel</h4>
                    </Col>
                    <Col sm={12}>
                        <Card body>
                           <Funnel data={this.state.funnel}></Funnel>
                        </Card>
                    </Col>
                </Container>


                <Container fluid className="mt-40">
                    <Col sm={12}>
                        <h4 className="page-heading">Conversations replied over time</h4>
                    </Col>
                    <Col sm={12}>
                        <Card body>
                            <Conversations data={this.state.crot}></Conversations>
                        </Card>
                    </Col>
                </Container>


                <Container fluid className="mt-40">
                    <Col sm={12}>
                        <h4 className="page-heading">Quantity of purchases</h4>
                    </Col>
                    <Col sm={12}>
                        <Card body>
                            <Quantityp data={this.state.qop}></Quantityp>
                        </Card>
                    </Col>
                </Container>


                <Container fluid className="mt-40">
                    <Col sm={12}>
                        <h4 className="page-heading">Recovery rate</h4>
                    </Col>
                    <Col sm={12}>
                        <Card body>
                            <Recovery data={this.state.rr}></Recovery>
                        </Card>
                    </Col>
                </Container>
            </>
        );
    }
}

export default Insight;