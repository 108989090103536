import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Card } from 'react-bootstrap';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import config from '../../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
let source = axios.CancelToken.source();

const columns = [
    {
        name: 'Date',
        selector: 'order_created_on',
        sortable: true,
        left: true,
        cell: row =><div data-tag="allowRowEvents">{row.order_created_on ? dateFormat(row.order_created_on) : ""}</div>,
    },

    {
        name: 'Order Number',
        selector: 'order_id',
        sortable: false,
        right: false
    },

    {
        name: 'Amount',
        selector: 'order_total',
        sortable: false,
        right: false,
        cell: row => <div>${row.order_total}</div>
    },

    {
        name: 'Details',
        selector: 'main_domain',
        sortable: false,
        right: true,
        cell: row =><a className='btn btn-sm btn-ot' href={"https://"+row.main_domain+"/admin/orders/"+row.order_id} target="_blank" rel="noreferrer">Order Details</a>,
    }
];

class Orders extends Component {
    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        loading:true,
        loading_text:"Loading...",
        tableloading:false,
        data:[{}],
        orders:[{}],
        total:0,
        page:1
    };

    setPage = (page) => {
        this.setState({page:page});
    }

    getData = () => {
        const tp_access_token = localStorage.getItem("tp_access_token");
        const tp_shop = localStorage.getItem("tp_shop");
        this.setState({tableloading:true});
        if (source) { source.cancel();}
        source = axios.CancelToken.source();
        axios.get(TP_API_URL+"client/orders/list", {
            params:{shop_id:tp_shop, page:this.state.page},
            headers:{token: tp_access_token},
            cancelToken: source.token
        }).then((res) => {
            const response = res.data;
            if(response.error_code===401){
                this.props.history.push("/login");
            } else if(response.error_code===0){
                this.setState({
                    loading:false,
                    tableloading:false,
                    data:response.data,
                    orders:response.orders
                });
            } else {
                this.setState({
                    loading_text:response.error_msg
                });
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
            this.setState({
                loading_text:e.message
            });
        });
    }


    componentDidMount(){
        let upage = parseInt(this.props.match.params.page);
        if(!upage){ upage=1;}
        this.setState({page:upage});
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.getData();
        }
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }


    render(){
        return (
            this.state.loading ? <div className='loading2'><p>{this.state.loading_text}</p></div> :
            <>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <h3 className="page-heading sidebar-btn">
                                Orders
                            </h3>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col className='billing'>
                            <h3 className="table-heading mt-0">
                                Recovered orders
                            </h3>
                            <Card body>
                                <DataTable
                                    noHeader={true}
                                    columns={columns}
                                    data={this.state.orders}
                                    highlightOnHover={false}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                    pagination={true}
                                    paginationServer={true}
                                    page={this.state.page}
                                    paginationTotalRows={this.state.data.total_row}
                                    paginationPerPage={50}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => this.setPage(page)}
                                    paginationDefaultPage={this.state.page}
                                    progressPending={this.state.tableloading}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Orders);